import React, { useState } from "react"
import styles from "./faq-section.module.scss"
import cblockStyles from "../../styles/commons/cblock.module.scss"

const FaqSection = ({ props }) => {
  const [questions, setQuestions] = useState(props.questions)
  const faqLink = "/docs/faqs"

  const toggleQuestion = (event, question) => {
    if (["a", "img"].includes(event.target.tagName.toLowerCase())) {
      // disabling toggle handler for question links & link icons
      return
    }

    event.preventDefault()
    setQuestions([
      ...questions.map(q => {
        if (q.title === question.title) {
          q.expanded = !q.expanded
        }
        return { ...q }
      }),
    ])
  }

  return (
    <section className={`${cblockStyles.cblock} ${styles.questions}`}>
      <div className={cblockStyles.cblock__header}>
        <h2>Frequently Asked Questions</h2>
      </div>
      <div>
        {questions &&
          questions.map(question => (
            <div
              key={question.title}
              onClick={event => toggleQuestion(event, question)}
              className={
                question.expanded
                  ? `${styles.question} ${styles.questionExpanded}`
                  : styles.question
              }>
              <h5 className={styles.question__title}>Q: {question.title}</h5>
              <span
                className={styles.question__answer}
                dangerouslySetInnerHTML={{ __html: question.answer }}
              />
              <div className={styles.question__toggle}>
                <div className={styles.question__toggleHorizontal} />
                <div className={styles.question__toggleVertical} />
              </div>
            </div>
          ))}
      </div>
      {props.faqLink && (
        <div className={styles.questions__footer}>
          Have more questions?{" "}
          <a
            href={props.faqLink || faqLink}
            className={styles.questions__footerLink}
            target="_blank">
            Visit our FAQ page
            <img
              alt="Documentation link"
              src="/img/products/external-link-selected.svg"
            />
          </a>
        </div>
      )}
    </section>
  )
}

export default FaqSection
