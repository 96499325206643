/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { graphql, useStaticQuery } from "gatsby"
import { ENV_SITE_URL } from "../utils/secrets"

function SEO(
  {
    title,
    pageUrl,
    description,
    keywords,
    imageName,
    twImageName,
    meta,
    isArticle,
    author,
    publishedAt,
    lang,
    dontIndex,
    external,
    addCanonical,
  } = {
    keywords: [],
    meta: [],
    lang: "en_US",
  }
) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteName
            description
            author
            facebookAppId
            twitterHandle
            siteLanguage
          }
        }
      }
    `
  )

  const metaTitle = title
  const metaDescription = description || site.siteMetadata.description
  const metaKeywords = Array.isArray(keywords) ? keywords.join(", ") : keywords // keywords is either array or string
  const metaImageUrl = ENV_SITE_URL + (imageName || "/previews/overview.png")
  const metaTwImageUrl =
    ENV_SITE_URL + (twImageName || imageName || "/previews/tw/overview.png")

  const robotsTag = []
  if (dontIndex) {
    robotsTag.push({
      name: "robots",
      content: "none, noimageindex",
    })
  }

  const facebookTags = [
    {
      property: `og:site_name`,
      content: site.siteMetadata.siteName,
    },
    {
      property: `og:title`,
      content: metaTitle,
    },
    {
      property: `og:description`,
      content: metaDescription,
    },
    {
      property: `og:image`,
      content: metaImageUrl,
    },
    {
      property: `og:type`,
      content: isArticle ? "article" : "website",
    },
    {
      property: `og:article:published_time`,
      content: publishedAt,
    },
    {
      property: `og:article:author`,
      content: author,
    },
    {
      property: `og:locale`,
      content: lang,
    },
    {
      property: `fb:app_id`,
      content: site.siteMetadata.facebookAppId,
    },
  ]

  const twitterTags = [
    {
      name: `twitter:card`,
      content: `summary`,
    },
    {
      name: `twitter:site`,
      content: site.siteMetadata.twitterHandle,
    },
    {
      name: `twitter:site:id`,
      content: site.siteMetadata.twitterId,
    },
    {
      name: `twitter:creator`,
      content: author,
    },
    {
      name: `twitter:title`,
      content: metaTitle,
    },
    {
      name: `twitter:description`,
      content: metaDescription,
    },
    {
      name: `twitter:image`,
      content: metaTwImageUrl,
    },
    {
      name: `twitter:image:src`,
      content: metaTwImageUrl,
    },
  ]

  const pinterestTags = [
    {
      name: `article:published_time`,
      content: publishedAt,
    },
    {
      name: `article:author`,
      content: author,
    },
  ]

  if (pageUrl) {
    const metaPageUrl = external ? pageUrl : ENV_SITE_URL + pageUrl
    const metaUrl = metaPageUrl.replace(/\/$/, "")
    facebookTags.push({
      property: `og:url`,
      content: metaUrl,
    })
  }

  const canonicalTags = []
  if (addCanonical) {
    const canonicalUrl = external ? pageUrl : ENV_SITE_URL + pageUrl
    canonicalTags.push({
      rel: "canonical",
      href: canonicalUrl,
    })
  }

  const allMeta = [
    {
      name: "description",
      content: metaDescription,
    },
    {
      name: "keywords",
      content: metaKeywords,
    },
    ...robotsTag,
    ...facebookTags,
    ...twitterTags,
    ...pinterestTags,
  ].filter(tag => !!tag.content)

  return (
    <Helmet
      htmlAttributes={{
        lang: site.siteMetadata.siteLanguage,
        prefix: "og: http://ogp.me/ns#",
      }}
      title={metaTitle}
      meta={allMeta}
      link={canonicalTags}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
