import React, { useEffect, useRef, useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import styles from "../styles/pricing.module.scss"
import cblockStyles from "../styles/commons/cblock.module.scss"
import Layout from "../components/layout/layout"
import withAppContext from "../contexts/app.context"
import DropdownArrowSvg from "../components/svg/dropdown-arrow"
import QuestionMarkSvg from "../components/svg/question-mark"
import FaqSection from "../components/faq-section/faq-section"
import SEO from "../components/seo"
import { ENV_CONSOLE_BASE_URL } from "../utils/secrets"
import checkStyles from "../styles/commons/circlechecks.module.scss"
import { navigate } from "gatsby"

const PricingPage = () => {
  const queryResult = useStaticQuery(graphql`
    {
      seo: markdownRemark(
        fields: { relativePath: { eq: "pricing" }, category: { eq: "seo" } }
      ) {
        frontmatter {
          metaTitle
        }
      }
    }
  `)

  const {
    seo: {
      frontmatter: { metaTitle, metaImageName },
    },
  } = queryResult

  const signUpClickHandler = () => {
    window.location.href = `${ENV_CONSOLE_BASE_URL}/signup/start`
  }
  const getQuoteClickHandler = () => {
    navigate("/company/contact-us", { state: { interest: "Talk to Sales" } })
  }

  const tableRef = useRef()
  const tableContainerRef = useRef()
  const tableBodyRef = useRef()
  const tableHeaderRef = useRef()

  const [isHeaderFloating, setHeaderFloating] = useState(false)

  useEffect(() => {
    const windowExists = typeof window !== "undefined"

    if (windowExists) {
      const resizeHandler = () => {
        tableHeaderRef.current.style.width = `${
          tableRef.current.getBoundingClientRect().width
        }px`

        handleTableHeaderFloating()
      }

      resizeHandler()
      window.addEventListener("resize", resizeHandler)
      return () => window.removeEventListener("resize", resizeHandler)
    }
  }, [])

  useEffect(() => {
    let tableContainer = tableContainerRef.current
    let tableHeader = tableHeaderRef.current
    let table = tableRef.current

    const handleXScroll = () => {
      const delta = tableContainer.scrollLeft
      const leftMargin = 15 // 15px
      tableHeader.style.left = `${-delta + leftMargin}px`
      tableHeader.style.width = `${table.getBoundingClientRect().width}px`

      // applying non-scrolling effect for mobile table header titles
      let mobileTableHeaders = document.querySelectorAll(
        `summary[class^='pricing-module--pricing__table-mobile-title']`
      )
      if (mobileTableHeaders && mobileTableHeaders.length) {
        mobileTableHeaders.forEach(header => {
          header.style.marginLeft = `${delta}px`
        })
      }
    }

    tableContainer.addEventListener("scroll", handleXScroll)
    return () => tableContainer.removeEventListener("scroll", handleXScroll)
  }, [])

  useEffect(() => {
    handleTableHeaderFloating()
    document.addEventListener("scroll", handleTableHeaderFloating)
    return () =>
      document.removeEventListener("scroll", handleTableHeaderFloating)
  }, [])

  const handleTableHeaderFloating = () => {
    const _websiteHeaderHeight = document
      .getElementById("header")
      .getBoundingClientRect().height

    const _tableHeaderHeight = tableHeaderRef.current.getBoundingClientRect()
      .height

    const _tableHeight = tableRef.current.getBoundingClientRect().height

    const tableTop =
      tableRef.current.getBoundingClientRect().top + window.pageYOffset

    const isFloating =
      window.pageYOffset > tableTop - _websiteHeaderHeight &&
      window.pageYOffset <
        tableTop + _tableHeight - _tableHeaderHeight - _websiteHeaderHeight

    const tableContainer = tableContainerRef.current
    const containerFullWidth =
      tableContainer.getBoundingClientRect().width +
      parseFloat(window.getComputedStyle(tableContainer).marginLeft) +
      parseFloat(window.getComputedStyle(tableContainer).marginRight)

    // resetting left property for large desktop values
    if (containerFullWidth > 1200) {
      tableHeaderRef.current.style.left = null
    }

    tableHeaderRef.current.style.top = `${_websiteHeaderHeight}px`
    setHeaderFloating(isFloating)
  }

  const getDropdownItemName = (item, index) => {
    if (index === 1) {
      return "Volterra ADN - Network & Security Services"
    } else if (index === 3) {
      return "Multi-Cloud or Edge - Network & Security Services"
    }
    return item
  }

  const freeFeatures = [
      "App Delivery Network (ADN)",
      "App security (DDoS mitigation)",
      "Secure Kubernetes gateway",
      "Cloud/edge app management",
      "Shape: Device ID",
      "VoltShare",
      "Limited support (ticketing+email)",
    ],
    individualFeatures = [
      "App security (WAFs) & performance",
      "Load balancing (globally distributed)",
      "Cloud/edge networking and security",
      "Standard support (ticketing+email)",
    ],
    teamFeatures = [
      "Volterra edge hardware",
      "Single-sign-on (SSO)",
      "Uptime SLAs",
      "Advanced support",
    ],
    organizationFeatures = [
      "API gateway (globally distributed)",
      "App security (API discovery & control)",
      "App security (BOT mitigation)",
      "DDoS mitigation and network services",
      "Premium support",
    ]

  const featuresList = [
    ...freeFeatures,
    ...individualFeatures,
    ...teamFeatures,
    ...organizationFeatures,
  ]
  const [selectedTab, setSelectedTab] = useState("Free")

  const tableContent = [
    {
      feature: "Volterra ADN - Load Balancing, API Gateway & Security Services",
      rows: [
        {
          name: "Load Balancing (Globally Distributed)",
          cells: ["Included", "Included", "Included", "Included"],
        },
        {
          name: "Health-Checks (all locations)",
          secondary: true,
          cells: ["120 sec", "30 sec", "15 sec", "1 sec"],
        },
        {
          name: "# of Endpoints (origin servers)",
          secondary: true,
          cells: ["2", "4", "50", "Unlimited"],
        },
        {
          name: "# of VIPs included",
          secondary: true,
          cells: ["Shared VIP", "Shared VIP", "Shared VIP", "1 VIP incl."],
        },
        {
          name: "Global Anycast IP",
          secondary: true,
          cells: ["Included", "Included", "Included", "Included"],
        },
        {
          name: "Additional VIP",
          secondary: true,
          cells: ["-", "-", "$50/VIP", "Custom"],
        },
        {
          name: "DNS Domain Delegation",
          secondary: true,
          cells: ["Included", "Included", "Included", "Included"],
        },
        {
          name: "Automated SSL Certificates",
          secondary: true,
          cells: ["Included", "Included", "Included", "Included"],
        },
        {
          name: "BYO Certificate",
          secondary: true,
          cells: ["Included", "Included", "Included", "Included"],
        },
        {
          name: "Blindfold SSL Certificates",
          secondary: true,
          cells: ["Included", "Included", "Included", "Included"],
        },
        {
          name: "BYO VIPs",
          secondary: true,
          cells: ["-", "-", "-", "Custom"],
        },
        {
          name: "Secure Networking",
          cells: ["Included", "Included", "Included", "Included"],
        },
        {
          name: "Fast ACLs",
          secondary: true,
          cells: ["-", "-", "100", "Custom"],
        },
        {
          name: "App Security (Basic WAFs)",
          cells: ["-", "Included", "Included", "Included"],
        },
        {
          name: "Service Policy Rules",
          tooltip: `e.g. JS Challenge, L7 Policy, Custom Rules`,
          secondary: true,
          cells: ["5", "10", "100", "Custom"],
        },
        {
          name: "Rate Limit Policy Rules",
          secondary: true,
          cells: ["-", "5", "25", "Custom"],
        },
        {
          name: "Price/request for Rate limit",
          secondary: true,
          cells: [
            "-",
            "$0.05/10K (good req)",
            "$0.05/10K (good req)",
            "Custom",
          ],
        },
        {
          name: "Bot Management",
          secondary: true,
          cells: ["-", "-", "-", "Included"],
        },
        {
          name: "API Discovery & Control",
          secondary: true,
          tooltip: `per Network PoP location`,
          cells: ["-", "-", "-", "Custom"],
        },
        {
          name: "API Gateway (Globally Distributed)",
          cells: ["-", "-", "-", "Custom"],
        },
        {
          name: "Secure Backbone",
          cells: ["-", "-", "-", "Custom"],
        },
        {
          name: "Network DDOS (On Demand)",
          secondary: true,
          tooltip: `
          - Protection using BGP-based Redirect <br />
          - Includes 18 mitigations for 90 attack hours per year <br />
          - Supports flow records from 2 routers <br />
          - Additional on-net charge >12 hours after mitigation <br />
          - Includes 10 active rules
          `,
          cells: ["-", "-", "-", "Custom"],
        },
        {
          name: "Network DDOS (Unlimited - BGP-based)",
          secondary: true,
          tooltip: `
          - Unlimited Mitigation for unlimited attack hours per year <br />
          - Supports flow records from 2 routers. <br />
          - Additional on-net charge >12 hours after mitigation <br />
          - Includes 10 active rules
          `,
          cells: ["-", "-", "-", "Custom"],
        },
        {
          name: "Additional ASN",
          secondary: true,
          cells: ["-", "-", "-", "Custom"],
        },
        {
          name: "Additional VIP",
          secondary: true,
          cells: ["-", "-", "-", "Custom"],
        },
        {
          name: "Additional Rules (/10 rules)",
          secondary: true,
          cells: ["-", "-", "-", "Custom"],
        },
        {
          name: "Additional Routers (flow records)",
          secondary: true,
          cells: ["-", "-", "-", "Custom"],
        },
        {
          name: "Additional 30 hours of Mitigation",
          secondary: true,
          cells: ["-", "-", "-", "Custom"],
        },
        {
          name: "VoltMesh Direct Connect (10Gbps)",
          secondary: true,
          tooltip: `Extra: metro connectivity and colo <br /> cross-connect`,
          cells: ["-", "-", "-", "Custom"],
        },
        {
          name: "VoltMesh Direct Connect (40/100Gbps)",
          secondary: true,
          tooltip: `Extra: metro connectivity and colo <br /> cross-connect`,
          cells: ["-", "-", "-", "Custom"],
        },
      ],
    },
    {
      feature: "Volterra ADN - App Management",
      rows: [
        {
          name: "VoltStack - Tiny Containers",
          tooltip: `0.1 vCPU, 100 Mib RAM, 1GB local storage`,
          cells: ["Included", "$0.01/hr", "$0.01/hr", "Custom"],
        },
        {
          name: "VoltStack -  Medium Containers",
          tooltip: `0.25 VCPU, 250 Mib RAM, 2.5 GB local storage`,
          cells: ["Included", "$0.02/hr", "$0.02/hr", "Custom"],
        },
        {
          name: "VoltStack -  Large Containers",
          tooltip: `1 VCPU, 1000 Mib RAM, 5GB local storage`,
          cells: ["Included", "$0.05/hr", "$0.06/hr", "Custom"],
        },
        {
          name: "Identity and Secrets Mgmt",
          cells: ["Included", "$0.05/10K req", "$0.05/10K req", "Included"],
        },
        {
          name: "Key Management System (KMS)",
          cells: ["-", "-", "-", "Included"],
        },
        {
          name: "Distributed Block Storage",
          cells: ["Included", "$0.10/GB/mo", "$0.12/GB/mo", "Custom"],
        },
        {
          name: "Distributed Object Storage",
          cells: ["-", "-", "-", "Custom"],
        },
        {
          name: "Traffic Charges (between Volterra Sites)",
          cells: ["Rate-limited", "$0.05/GB", "$0.05/GB", "Custom"],
        },
        {
          name: "Traffic Charges (outbound to Internet)",
          cells: ["Rate-limited", "$0.05/GB", "$0.05/GB", "Custom"],
        },
      ],
    },
    {
      feature:
        "Multi-Cloud or Edge - Networking, Loading Balancing, K8s/API Gateway & Security Services",
      rows: [
        {
          name: "VoltMesh - Small node (4 vCPU)",
          cells: ["Included", "$0.07/hr", "$0.56/hr", "Custom"],
        },
        {
          name: "VoltMesh -Medium node (8 vCPU)",
          cells: ["-", "$0.21/hr", "$1.39/hr", "Custom"],
        },
        {
          name: "VoltMesh - Large node (16 vCPU)",
          cells: ["-", "$0.38/hr", "$1.94/hr", "Custom"],
        },
      ],
    },
    {
      feature: "Multi-Cloud or Edge - App Management",
      rows: [
        {
          name: "VoltStack-Combo - Small (4 vCPU)",
          cells: ["Included", "$0.14/hr", "$0.42/hr", "Custom"],
        },
        {
          name: "VoltStack-Combo - Medium (8 vCPU)",
          cells: ["-", "$0.20/hr", "$0.70/hr", "Custom"],
        },
        {
          name: "VoltStack-Combo - Large (16 vCPU)",
          cells: ["-", "$0.35/hr", "$1.11/hr", "Custom"],
        },
        {
          name: "App management (Containers per site)",
          cells: ["15", "15", "50", "Included"],
        },
        {
          name: "Identity and Secrets Mgmt",
          cells: ["-", "$0.05/10K req", "$0.05/10K req", "Included"],
        },
        {
          name: "Key Management System (KMS)",
          cells: ["-", "-", "-", "Included"],
        },
        {
          name: "Local Storage (Block Storage)",
          cells: ["Included", "Included", "Included", "Included"],
        },
        {
          name: "Distributed Storage (Block Storage)",
          cells: ["-", "-", "-", "Custom"],
        },
      ],
    },
    {
      feature: "Multi-Cloud or Edge - Add-ons",
      rows: [
        {
          name: "App Firewall (per node)",
          cells: ["-", "$0.25/hr", "$0.45/hr", "Custom"],
        },
        {
          name: "API Discovery & Control",
          secondary: true,
          cells: ["-", "-", "-", "Included"],
        },
        {
          name: "API Gateway (Globally Distributed)",
          cells: ["-", "-", "-", "Custom"],
        },
        {
          name: "Certified Edge Hardware Support",
          cells: ["Included", "Included", "Included", "Included"],
        },
        {
          name: "Volterra Edge Hardware",
          cells: ["-", "-", "-", "Custom"],
        },
        {
          name: "Traffic Charges (to Internet or Customer Network)",
          cells: ["Included", "Included", "Included", "Included"],
        },
        {
          name: "Traffic Charges (to Volterra Network)",
          cells: ["Rate-limited", "$0.05/GB", "$0.05/GB", "Custom"],
        },
      ],
    },
    {
      feature: "Shape",
      rows: [
        {
          name: "Device ID",
          cells: ["Included", "Included", "Included", "Included"],
          tooltip: `JS tag download & Monitoring Dashboards`,
        },
      ],
    },
    {
      feature: "Personal Computing Devices - VoltShare",
      rows: [
        {
          name: "VoltShare",
          cells: ["Included", "Included", "Included", "Included"],
        },
      ],
    },
    {
      feature: "Limits",
      rows: [
        {
          name: "Distributed App Management",
          tooltip: `Total number of containers per Volterra Network PoP`,
          cells: ["1", "5", "25", "No limits"],
        },
        {
          name: "Block Storage (per Volterra PoP)",
          tooltip: `Total GBs per Volterra Network PoP`,
          cells: ["5GB", "50GB", "100GB", "Custom"],
        },
        {
          name: "# of Load Balancers (Public)",
          cells: ["1", "1", "1", "No Limits"],
        },
        {
          name: "# of Load Balancers (Internal/Private)",
          cells: ["2", "5", "25", "No Limits"],
        },
        {
          name: "# of App Firewall instances",
          cells: ["0", "5", "25", "No Limits"],
        },
        {
          name: "# of Cloud / Edge Sites",
          cells: ["2", "5", "25", "No Limits"],
        },
        {
          name: "# of Delegated Domains (DNS)",
          cells: ["1", "1", "2", "Custom"],
        },
        {
          name: "Shape",
          cells: ["", "", "", ""],
          subheading: true,
        },
        {
          name: "Device ID",
          cells: ["20M", "20M", "20M", "Custom"],
          secondary: true,
        },
      ],
    },
    {
      feature: "Observability",
      rows: [
        {
          name: "Metrics",
          cells: ["1 hr", "6 hrs", "7 days", "30 days"],
        },
        {
          name: "Request Logs",
          cells: ["1 hr", "6 hrs", "7 days", "30 days"],
        },
        {
          name: "Audit Logs",
          cells: ["-", "6 hrs", "7 days", "30 days"],
        },
        {
          name: "Log Export",
          cells: ["-", "Included", "Included", "Included"],
        },
        {
          name: "Alerts / Notifications (Policy Rules)",
          cells: ["1", "5", "25", "Included"],
        },
      ],
    },
    {
      feature: "Administration",
      rows: [
        {
          name: "# of users",
          cells: ["1", "1", "25", "Unlimited"],
        },
        {
          name: "SSO",
          cells: ["-", "-", "Included", "Included"],
        },
        {
          name: "RBAC",
          cells: ["-", "-", "Included", "Included"],
        },
      ],
    },
    {
      feature: "Support",
      rows: [
        {
          name: "Type",
          cells: [
            "Limited Support",
            "Standard Support",
            "Advanced Support",
            "Premium Support",
          ],
        },
        {
          name: "Response SLA",
          cells: ["Best Effort (or 24hr)", "12 hour", "4 hour", "1 hour"],
        },
        {
          name: "Email Support",
          cells: ["Yes", "Yes", "Yes", "Yes"],
        },
        {
          name: "Support Ticketing",
          cells: ["Yes", "Yes", "Yes", "Yes"],
        },
        {
          name: "Phone/Emergency",
          cells: ["-", "-", "-", "Yes"],
        },
        {
          name: "Uptime SLA",
          cells: ["-", "-", "99.99%", "99.99%"],
        },
        {
          name: "Service Credits",
          tooltip: `for Volterra Global Network downtime`,
          cells: ["-", "-", "1x", "Custom"],
        },
        {
          name: "Customer Success Team",
          cells: ["-", "-", "-", "Yes"],
        },
        {
          name: "Training",
          cells: ["-", "-", "-", "Access to Training"],
        },
      ],
    },
  ]

  const [isDropdownExpanded, setDropdownExpanded] = useState(false)
  const [selectedTableFeature, setSelectedTableFeature] = useState(
    "All Features"
  )

  const questions = [
    {
      title: "Can I use Volterra for free?",
      expanded: false,
      answer: `
      Yes, we provide a completely free plan that provides both VoltMesh and VoltStack services for your test/dev or production needs.
      `,
    },
    {
      title: "What can I do with the free plan?",
      expanded: false,
      answer: `
      <p>The free plan gives you the flexibility to deploy various different capabilities:</p>
      <ol>
        <li>Deploy containerized apps in our global application delivery network using your existing K8s deployment spec. You can deploy up-to 8 containers across our global network to deliver lightning-fast performance to a global audience — no need to manage any K8s clusters, load balancers, or worry about the security of the app.</li>
        <li>Secure an already running app in one or two cloud locations (even across two different cloud providers). It is as simple as configuring a virtual load-balancer in our network and providing the IP addresses of the origin server(s). If you don't want your origin servers to be exposed to the public internet, you also get the flexibility to deploy up to two VoltMesh nodes in each of the cloud location(s) to privately and securely connect your origin servers to our global network without exposing your app to the public internet.</li>
        <li>Deploy VoltStack software on a VM in your public/private cloud location(s) or on a <a target="_blank" href="/docs/how-to/site-management/create-baremetal-site">certified hardware</a> in your edge location. This gives you a fully managed app platform that is not only distributed in the cloud/edge but also in our network (with up to 8 containers in our global network). You can deploy and operate as many apps in your cloud or edge location.</li>
      </ol>
      <p>You can create many other combinations based on your imagination - for example, deploying SDWAN across two locations and creating networks and security policies across these two locations and to the public internet. These are just some of the good examples that we can think of and we are sure that you will dream of many more.</p>
      `,
    },
    {
      title:
        "Why would I need to upgrade from the free to a paid individual plan?",
      expanded: false,
      answer: `
      <p>You will need to upgrade from the free plan to the individual plan if you scale your deployment beyond the noted levels or need to manage more apps or locations.  Alternatively, you might require the advanced security services, additional analytics data, or advanced support that comes with our teams or organization plan. These two higher value plans will require you to sign-up directly for one of these plans or open a support ticket to move from free/individual to one of these plans.</p> 
      `,
    },
    {
      title: "What will be my billing date?",
      expanded: false,
      answer: `
      <p>The date you migrate from the free to a paid plan will be your recurring billing date. If you directly signed-up for a paid plan, your sign-up date will be the recurring billing date.</p>
      <p>For Organization plans only: In case you signed up for an organization plan, the billing date will be the end of the month. If you signed up for the organization plan anytime in the middle of the month, the first bill will be at the end of the month, prorated based on the date you signed up. Subsequent billing dates will be at the end of each month.</p>
      `,
    },
    {
      title: "When do you charge my payment method?",
      expanded: false,
      answer: `
      There are two major components of your bill, a fixed subscription fee charge and variable charges based service usage. There is one bill generated at the end of the billing cycle that includes both the fixed subscription fee charge and the variable charge. And there will be one transaction at the end of the billing cycle on your payment method.   
      `,
    },
    {
      title: "Can I get a preview of my usage before I am billed?",
      expanded: false,
      answer: `
      <p>Yes you view the cycle to date usage and costs incurred in the billing section of VoltConsole. You can view usage details per hour in the “Usage Details” page in the billing section of VoltConsole. In addition, you can get a cycle to date summary of your bill in the “Usage Summary” page in the billing section of VoltConsole.</p>
      `,
    },
    {
      title: "What happens when I upgrade or downgrade my plan?",
      expanded: false,
      answer: `
      <p>The upgrade and downgrade is currently supported for</p>
      <ul>
      <li>from/to  free and individual plans</li>
      <li>from/to teams and organization plans. For teams and organization plan, downgrade to free plan requires you to close the account and open a separate free or individual plan.</li>
      </ul>
      `,
    },
    {
      title: "What happens if my organization is tax exempt",
      expanded: false,
      answer: `
      <p>If you are eligible for tax exemption, you can request “Tax Exempt Status” by submitting requisite documentation such as Tax Exempt Certificate on VoltConsole under Billing Settings section. Please refer to the <a target="_blank" href="/docs/billing/tax-exempt-billing">Tax exempt documentation</a> for more details</p>
      `,
    },
  ]

  return (
    <Layout>
      <SEO
        title={metaTitle}
        pageUrl={"/pricing"}
        imageName={!!metaImageName ? `/previews/${metaImageName}` : undefined}
      />

      <section className={`${cblockStyles.cblock} ${styles.pricing}`}>
        <div className={`container volterra-container ${styles.pricing__top}`}>
          <div className={styles.pricing__header}>
            <h1 className={styles.pricing__title}>Pricing</h1>
            <p className={styles.pricing__description}>
              Distributed Cloud Services — In Every Package
            </p>
          </div>

          <div className={styles.pricing__tabs}>
            <div
              onClick={() => {
                setSelectedTab("Free")
              }}
              className={
                selectedTab === "Free"
                  ? `${styles.pricing__tab} ${styles.pricing__tabActive}`
                  : styles.pricing__tab
              }>
              <h5 className={styles.pricing__tabTitle}>Free</h5>
              <span className={styles.pricing__tabRate}>$0/mo</span>
            </div>
            <div
              onClick={() => {
                setSelectedTab("Individual")
              }}
              className={
                selectedTab === "Individual"
                  ? `${styles.pricing__tab} ${styles.pricing__tabActive}`
                  : styles.pricing__tab
              }>
              <h5 className={styles.pricing__tabTitle}>Individual</h5>
              <span className={styles.pricing__tabRate}>$25/mo</span>
            </div>
            <div
              onClick={() => {
                setSelectedTab("Teams")
              }}
              className={
                selectedTab === "Teams"
                  ? `${styles.pricing__tab} ${styles.pricing__tabActive}`
                  : styles.pricing__tab
              }>
              <h5 className={styles.pricing__tabTitle}>Teams</h5>
              <span className={styles.pricing__tabRate}>$200/mo</span>
            </div>
            <div
              onClick={() => {
                setSelectedTab("Organization")
              }}
              className={
                selectedTab === "Organization"
                  ? `${styles.pricing__tab} ${styles.pricing__tabActive}`
                  : styles.pricing__tab
              }>
              <h5 className={styles.pricing__tabTitle}>Organization</h5>
              <span className={styles.pricing__tabRate}>Quote</span>
            </div>
          </div>

          <div className={styles.pricing__features}>
            {featuresList.map((feature, index) => {
              let thresholdIndex
              switch (selectedTab) {
                case "Free":
                  thresholdIndex = 6
                  break
                case "Individual":
                  thresholdIndex = 10
                  break
                case "Teams":
                  thresholdIndex = 14
                  break
                case "Organization":
                  thresholdIndex = 20
              }
              return (
                <span
                  key={feature}
                  className={`${styles.pricing__feature} ${
                    index < thresholdIndex ? styles.pricing__featureEnabled : ""
                  }`}>
                  {feature}
                </span>
              )
            })}
            <button
              type="button"
              className={`${styles.pricing__featuresBtn}`}
              onClick={
                selectedTab === "Organization"
                  ? getQuoteClickHandler
                  : signUpClickHandler
              }>
              {selectedTab === "Organization" ? "Get a quote" : "Get started"}
            </button>
          </div>
        </div>

        <div className={`container ${styles.pricing__mobileTabsContainer}`}>
          <div className={styles.pricing__mobileTabs}>
            <div className={styles.pricing__mobileTab}>
              <h5 className={styles.pricing__mobileTabTitle}>Free</h5>
              <p className={styles.pricing__mobileTabDescription}>
                Distribute apps in our network or across cloud or edge
              </p>
              <p className={styles.pricing__mobileTabPrice}>$0</p>
              <button
                className={styles.pricing__mobileTabBtn}
                onClick={signUpClickHandler}>
                Get started
              </button>
              <ul className={`list-unstyled ${checkStyles.circlechecks}`}>
                {freeFeatures.map(feature => (
                  <li key={feature} className={checkStyles.circlechecks__item}>
                    {feature}
                  </li>
                ))}
              </ul>
            </div>
            <div className={styles.pricing__mobileTab}>
              <h5 className={styles.pricing__mobileTabTitle}>Individual</h5>
              <p className={styles.pricing__mobileTabDescription}>
                Accelerate and secure your apps in cloud, edge, or our network
              </p>
              <p className={styles.pricing__mobileTabPrice}>
                $25
                <span className={styles.pricing__mobileTabPriceRate}>
                  Montly
                </span>
              </p>
              <button
                className={styles.pricing__mobileTabBtn}
                onClick={signUpClickHandler}>
                Get started
              </button>
              <ul className={`list-unstyled ${checkStyles.circlechecks}`}>
                <li className={checkStyles.circlechecks__item}>
                  Everything in <b>Free</b>
                </li>
                {individualFeatures.map(feature => (
                  <li key={feature} className={checkStyles.circlechecks__item}>
                    {feature}
                  </li>
                ))}
              </ul>
            </div>
            <div className={styles.pricing__mobileTab}>
              <h5 className={styles.pricing__mobileTabTitle}>Teams</h5>
              <p className={styles.pricing__mobileTabDescription}>
                Accelerate and secure your apps in cloud, edge, or our network
              </p>
              <p className={styles.pricing__mobileTabPrice}>
                $200
                <span className={styles.pricing__mobileTabPriceRate}>
                  Montly
                </span>
              </p>
              <button
                className={styles.pricing__mobileTabBtn}
                onClick={signUpClickHandler}>
                Get started
              </button>
              <ul className={`list-unstyled ${checkStyles.circlechecks}`}>
                <li className={checkStyles.circlechecks__item}>
                  Everything in <b>Individual</b>
                </li>
                {teamFeatures.map(feature => (
                  <li key={feature} className={checkStyles.circlechecks__item}>
                    {feature}
                  </li>
                ))}
              </ul>
            </div>
            <div className={styles.pricing__mobileTab}>
              <h5 className={styles.pricing__mobileTabTitle}>Organization</h5>
              <p className={styles.pricing__mobileTabDescription}>
                Accelerate and secure your apps in cloud, edge, or our network
              </p>
              <p className={styles.pricing__mobileTabPrice}>Quote</p>
              <button
                className={styles.pricing__mobileTabBtn}
                onClick={getQuoteClickHandler}>
                Get a qoute
              </button>
              <ul className={`list-unstyled ${checkStyles.circlechecks}`}>
                <li className={checkStyles.circlechecks__item}>
                  Everything in <b>Teams</b>
                </li>
                {organizationFeatures.map(feature => (
                  <li key={feature} className={checkStyles.circlechecks__item}>
                    {feature}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>

        <div
          ref={tableContainerRef}
          className={`container volterra-container ${styles.pricing__tableContainer}`}>
          <div
            ref={tableRef}
            className={`${styles.pricing__table} ${
              isHeaderFloating ? styles.pricing__tableFloat : ""
            }`}>
            <div
              ref={tableHeaderRef}
              className={`${styles.pricing__tableTitle}`}>
              <div
                onClick={() => {
                  setDropdownExpanded(!isDropdownExpanded)
                }}
                className={
                  isDropdownExpanded
                    ? `${styles.pricing__tableDropdown} ${styles.pricing__tableDropdownExpanded}`
                    : styles.pricing__tableDropdown
                }>
                <span className={styles.pricing__tableDropdownText}>
                  {selectedTableFeature}
                </span>
                <span className={styles.pricing__tableDropdownIcon}>
                  <DropdownArrowSvg />
                </span>
                <div className={styles.pricing__tableDropdownOptions}>
                  {[
                    "All Features",
                    ...tableContent.map(item => item.feature),
                  ].map((feature, index) => (
                    <span
                      key={feature}
                      onClick={() => {
                        setSelectedTableFeature(feature)
                        window.scrollTo(0, tableRef.current.offsetTop - 160)
                      }}
                      className={
                        selectedTableFeature === feature
                          ? `${styles.pricing__tableDropdownOption} ${styles.pricing__tableDropdownOptionSelected}`
                          : styles.pricing__tableDropdownOption
                      }>
                      {getDropdownItemName(feature, index)}
                    </span>
                  ))}
                </div>
              </div>
              <div className={styles.pricing__tableTitlePlan}>
                <p className={styles.pricing__tableTitlePlanName}>Free</p>
                <span className={styles.pricing__tableTitlePlanRate}>
                  $0 / mo
                </span>
                <button
                  className={styles.pricing__tableTitlePlanBtn}
                  onClick={signUpClickHandler}>
                  Get started
                </button>
              </div>
              <div className={styles.pricing__tableTitlePlan}>
                <p className={styles.pricing__tableTitlePlanName}>Individual</p>
                <span className={styles.pricing__tableTitlePlanRate}>
                  $25 / mo
                </span>
                <button
                  className={styles.pricing__tableTitlePlanBtn}
                  onClick={signUpClickHandler}>
                  Get started
                </button>
              </div>
              <div className={styles.pricing__tableTitlePlan}>
                <p className={styles.pricing__tableTitlePlanName}>Teams</p>
                <span className={styles.pricing__tableTitlePlanRate}>
                  $200 / mo
                </span>
                <button
                  className={styles.pricing__tableTitlePlanBtn}
                  onClick={signUpClickHandler}>
                  Get started
                </button>
              </div>
              <div className={styles.pricing__tableTitlePlan}>
                <p className={styles.pricing__tableTitlePlanName}>
                  Organization
                </p>
                <span className={styles.pricing__tableTitlePlanRate}>
                  Quote
                </span>
                <button
                  className={styles.pricing__tableTitlePlanBtn}
                  onClick={getQuoteClickHandler}>
                  Get a quote
                </button>
              </div>
            </div>
            <div ref={tableBodyRef} className={styles.pricing__tableBody}>
              <div className={styles.pricing__tableBodyWrap}>
                {tableContent.map(item => (
                  <div
                    key={item.feature}
                    className={
                      selectedTableFeature === "All Features" ||
                      selectedTableFeature === item.feature
                        ? "d-block"
                        : "d-none"
                    }>
                    <div className={styles.pricing__tableHeader}>
                      {item.feature}
                    </div>
                    {item.rows &&
                      item.rows.map((row, idx) => (
                        <div
                          key={`${row.name}-${idx}`}
                          className={`row ${styles.pricing__tableRow}`}>
                          <div
                            className={
                              row.secondary
                                ? `col-4 ${styles.pricing__tableRowName} ${styles.pricing__tableRowNameSecondary}`
                                : `col-4 ${styles.pricing__tableRowName}
                                ${
                                  row.subheading
                                    ? `${styles.pricing__tableRowNameSubheading}`
                                    : ""
                                }`
                            }>
                            {row.name}
                            {row.tooltip && (
                              <span
                                className={styles.pricing__tableRowNameTooltip}>
                                <QuestionMarkSvg />
                                <div
                                  className={
                                    styles.pricing__tableRowNameTooltipBlock
                                  }
                                  dangerouslySetInnerHTML={{
                                    __html: row.tooltip,
                                  }}
                                />
                              </span>
                            )}
                          </div>
                          {row.cells &&
                            row.cells.map((cell, idx) => (
                              <div
                                key={`${cell}-${idx}`}
                                className={`col-2 ${styles.pricing__tableRowCell}`}>
                                {cell}
                              </div>
                            ))}
                        </div>
                      ))}
                  </div>
                ))}
              </div>
              <div className={styles.pricing__tableMobileBody}>
                {tableContent.map((item, itemIndex) => (
                  <details
                    key={item.feature}
                    open={itemIndex === 0}
                    className={styles.pricing__tableMobileHeader}>
                    <summary className={styles.pricing__tableMobileTitle}>
                      <span>{item.feature}</span>
                    </summary>
                    <div className={styles.pricing__tableMobileRows}>
                      {item.rows &&
                        item.rows.map((row, idx) => (
                          <div
                            key={`${row.name}-${idx}`}
                            className={`${styles.pricing__tableMobileRow}
                            ${
                              row.subheading
                                ? `${styles.pricing__tableMobileRowSubheading}`
                                : ""
                            }`}>
                            {row.cells &&
                              row.cells.map((cell, cellIdx) => (
                                <div
                                  key={`${cell}-${cellIdx}`}
                                  className={styles.pricing__tableMobileCell}>
                                  <p
                                    className={
                                      styles.pricing__tableMobileCellValue
                                    }>
                                    {cell === "Included" ? (
                                      <img
                                        src="/img/common/blue-li.svg"
                                        alt="Included"
                                      />
                                    ) : cell === "-" ? (
                                      <img
                                        src="/img/common/disabled-li.svg"
                                        alt="Excluded"
                                      />
                                    ) : (
                                      cell
                                    )}
                                  </p>
                                  <p
                                    className={
                                      row.secondary
                                        ? `${styles.pricing__tableMobileCellName} ${styles.pricing__tableMobileCellNameSecondary}`
                                        : styles.pricing__tableMobileCellName
                                    }>
                                    {row.name}
                                  </p>
                                </div>
                              ))}
                          </div>
                        ))}
                    </div>
                  </details>
                ))}
              </div>
            </div>
          </div>
        </div>

        <div
          className={`container volterra-container ${styles.pricing__questions}`}>
          <FaqSection props={{ questions }} />
        </div>
      </section>
    </Layout>
  )
}

export default withAppContext(PricingPage)
