import React from "react"

const QuestionMarkSvg = () => (
  <svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1">
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="pricing_3-updated-"
        transform="translate(-413.000000, -5030.000000)"
        fill="#A0A7C2"
        fillRule="nonzero">
        <path
          d="M421,5030 C416.6,5030 413,5033.6 413,5038 C413,5042.4 416.6,5046 421,5046 C425.4,5046 429,5042.4 429,5038 C429,5033.6 425.4,5030 421,5030 Z M421,5043 C420.4,5043 420,5042.6 420,5042 C420,5041.4 420.4,5041 421,5041 C421.6,5041 422,5041.4 422,5042 C422,5042.6 421.6,5043 421,5043 Z M422.5,5038.4 C422,5038.7 422,5038.8 422,5039 L422,5040 L420,5040 L420,5039 C420,5037.7 420.8,5037.1 421.4,5036.7 C421.9,5036.4 422,5036.3 422,5036 C422,5035.4 421.6,5035 421,5035 C420.6,5035 420.3,5035.2 420.1,5035.5 L419.6,5036.4 L417.9,5035.4 L418.4,5034.5 C418.9,5033.6 419.9,5033 421,5033 C422.7,5033 424,5034.3 424,5036 C424,5037.4 423.1,5038 422.5,5038.4 Z"
          id="Shape"
        />
      </g>
    </g>
  </svg>
)

export default QuestionMarkSvg
