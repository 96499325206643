import React from "react"

const DropdownArrowSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="6"
    height="5"
    viewBox="0 0 6 5">
    <path
      fill="#A0A7C2"
      fillRule="nonzero"
      d="M5.912.783A.5.5 0 0 0 5.5 0h-5a.5.5 0 0 0-.412.783l2.5 4a.5.5 0 0 0 .824 0l2.5-4z"
    />
  </svg>
)

export default DropdownArrowSvg
